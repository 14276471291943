import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import {
    Link,
    Outlet
} from "react-router-dom";
import NewBadge from "../../assets/new.png";
import baseURL from '../../config';
import './elenco.css';


const scrollToRef = (ref) => window.scrollTo(0, ref.offsetTop);

const LettereAlfabeto = (props) => {
    let letteraIniziale = "";
    const elencoIntellettuali = props.elencoIntellettuali;
    return (
        <div className='int__elenco-letterecontainer' key={"lettere_alfabeto_container"}>
            {elencoIntellettuali.map((intellettuale, i) => {
                const primaLettera = intellettuale.cognome.charAt(0);
                if (primaLettera !== letteraIniziale) {
                    letteraIniziale = primaLettera;
                    return(
                    <div className='int__elenco-lettere-button' onClick={()=>scrollToRef(props.refs.current[i])} key={i+letteraIniziale}>
                        <p>{primaLettera}</p>
                    </div>
                )};
            })}
        </div>       
    )
}


const Elenco = () => {
    const [searchString, setSearchString] = useState("");
    const [searchResult, setSearchResult] = useState([]);
    const [elencoIntellettuali, setElencoIntellettuali] = useState([]);
    const [schedePresenti, setSchedePresenti] = useState([]);
    const { t, i18n } = useTranslation();
    const [testo, setTesto] = useState(null);
    const [lang, setLang] = useState(null);
    const lettersRefs = useRef([]);

    // let i=0; //indice dell'array di refs alle lettere
    let letteraIniziale = "";

 /*    const compareResultsSchede = (a,b) => {
        if (a.intellettualiRelation.cognome < b.intellettualiRelation.cognome) return -1;
        if (a.intellettualiRelation.cognome > b.intellettualiRelation.cognome) return 1;
        if (a.intellettualiRelation.nome < b.intellettualiRelation.nome) return -1;
        if (a.intellettualiRelation.nome > b.intellettualiRelation.nome) return 1;
        return 0;
    }

    const compareResultsIntellettuali = (a,b) => {
        if (a.cognome < b.cognome) return -1;
        if (a.cognome > b.cognome) return 1;
        if (a.nome < b.nome) return -1;
        if (a.nome > b.nome) return 1;
        return 0;
    } */

    const compareResultsSchede = (a,b) => {
        if (a.intellettualiRelation.cognome.localeCompare(b.intellettualiRelation.cognome) === 0) {
            return (a.intellettualiRelation.nome.localeCompare(b.intellettualiRelation.nome));
        } else {
            return a.intellettualiRelation.cognome.localeCompare(b.intellettualiRelation.cognome)
        }
    }

    const compareResultsIntellettuali = (a,b) => {
        if (a.cognome.localeCompare(b.cognome) === 0) {
            return (a.nome.localeCompare(b.nome));
        } else {
            return a.cognome.localeCompare(b.cognome)
        }
    }

    useEffect(() => {
        lettersRefs.current = lettersRefs.current.slice(0, 26);
     }, [elencoIntellettuali])

    useEffect(() => {
        setLang(i18n.language.substring(0,2))
        if (i18n.language.substring(0,2) === "it") {
            axios.get(baseURL+"/contenuti/27").then((response) => {
              setTesto(response.data);
            });
          } else {
            axios.get(baseURL+"/contenuti/20").then((response) => {
              setTesto(response.data);
            });
          }
    },[t]);

    useEffect(() => {
        const lingua = i18n.language.substring(0,2) === "it" ? 1 : 2;
        const intellettualiURL = baseURL+`/intellettuali`;
        axios.get(intellettualiURL).then((response) => {
            //const results = response.data;
            let results = [];
            response.data.forEach(element => {
                if (element.alias) {
                    results.push({alias:true, id:element.id, nome:element.nome, cognome: element.alias, cognomeReale:element.cognome, dateEsistenza: element.dateEsistenza});
                }
                results.push({alias: false, id:element.id, nome:element.nome, cognome: element.cognome , dateEsistenza: element.dateEsistenza});
            });
            results.sort(compareResultsIntellettuali);
            setElencoIntellettuali(results);
            const schedeURL = baseURL+`/schede?filter={"where": {"and" : [{"pubblico" : "true"}, {"lingua" : ${lingua}}] },"fields": {"idIntellettuale" : true, "modificato":true, "id":true}}`
            axios.get(schedeURL).then((response) => {
                const results = response.data;
                setSchedePresenti(results);
            });
        });
    },[t]);

    useEffect(() => {
        const fetchData = () => {
            if (searchString !== "") {
                const lingua = i18n.language.substring(0,2) === "it" ? 1 : 2;
                let intellettualiFilter="";
                const intellettualiURL = baseURL+`/intellettuali?filter={"where": {"or" : [{"nome" :{"regexp": "/${searchString}/i"}},
                                                                                           {"cognome" :{"regexp": "/${searchString}/i"}},
                                                                                           {"alias" :{"regexp": "/${searchString}/i"}}
                                                                                          ]}}`;
                axios.get(intellettualiURL).then((response) => {
                    const results = response.data;
                    let intellettualiIds = results.map(result => result.id);
                    intellettualiFilter = `{"idIntellettuale" : {"inq":[${intellettualiIds}]}}`
                    const schedeURL = baseURL+`/schede?filter={"where": {"and" : [
                                            ${intellettualiFilter},
                                            {"lingua" : ${lingua}}, 
                                            {"pubblico" : "true"}] 
                                        }, "include": [
                    {
                    "relation": "intellettualiRelation"
                    }
                    ]}`
                    axios.get(schedeURL).then((response) => {
                        const results = response.data;
                        results.sort(compareResultsSchede);
                        setSearchResult(results);  
                    });
                });
            }            
        }
        const timer = setTimeout(() => {
            fetchData();
        }, 500);
        return () => clearTimeout(timer)
    },[searchString, t]);

    if(!testo) return null;

    if(searchResult.length===0 && searchString!=="") return (
        <>
        <div className="int__contenuti-navbar">
            <div className="int__contenuti-navbar-horiz">
                <div className="int__contenuti-navbar-left-container">
                    <div className="int__contenuti-navbar-left-container-box">
                        {lang==="it" ? 
                            <Link to={`/${lang}/viteinmovimento`} key={"viteinmovimento"}>
                                <button className="int__contenuti-pages-button">
                                    <img src={require("../../assets/left2.png")} alt="Left" className="int__contenuti-icons"></img>
                                </button>
                                <div className='int__contenuti-navbar-title'>{t("Vite in movimento")} </div>
                            </Link>
                            :
                            <Link to={`/${lang}/livesonthemove`} key={"viteinmovimento"}>
                                <button className="int__contenuti-pages-button">
                                    <img src={require("../../assets/left2.png")} alt="Left" className="int__contenuti-icons"></img>
                                </button>
                                <div className='int__contenuti-navbar-title'>{t("Vite in movimento")} </div>
                            </Link>
                        }
                    </div>
                </div>
                <div className="int__contenuti-navbar-right-container">
                    <div className="int__contenuti-navbar-right-container-box">
                        {lang==="it" ? 
                            <Link to={`/${lang}/reti_familiari`} key={"retifamiliari"}>
                                <button className="int__contenuti-pages-button">
                                    <img src={require("../../assets/right2.png")} alt="Right" className="int__contenuti-icons"></img>
                                </button>
                                <div className='int__contenuti-navbar-title'>{t("Reti familiari")}</div>
                            </Link>
                            :
                            <Link to={`/${lang}/family_networks`} key={"retifamiliari"}>
                                <button className="int__contenuti-pages-button">
                                    <img src={require("../../assets/right2.png")} alt="Right" className="int__contenuti-icons"></img>
                                </button>
                                <div className='int__contenuti-navbar-title'>{t("Reti familiari")}</div>
                            </Link>
                        }
                    </div>
                </div>
            </div>
        </div>
        <div className="int__contenuti-testo-container-vert">
            <div className='int__contenuti-testo-container-horiz'>
                <div className="int__contenuti-title-name">
                    {testo.titolo}
                </div>
                <div className="int__contenuti-testo" dangerouslySetInnerHTML={{__html: testo.testo}}>
                </div>
            </div>
        </div>
        <div className='int__elenco-global-container-vert'>
            <div className='int__elenco-global-container-horiz'>
                <div className='int__elenco-container'>
                    <div className='int__elenco-form-container'> 
                        <p>{t("Cerca nomi")}</p>
                        <div className='int__elenco-form'>
                            <input value={searchString}  onChange={(e) => setSearchString(e.target.value)} type="text" />    
                        </div>                                         
                    </div>                
                </div>
            </div>
        </div>
        </>
    )

    if (schedePresenti.length===0) return null;

    return (
    <>
    <div className="int__contenuti-navbar">
        <div className="int__contenuti-navbar-horiz">
            <div className="int__contenuti-navbar-left-container">
                <div className="int__contenuti-navbar-left-container-box">
                    {lang==="it" ? 
                        <Link to={`/${lang}/viteinmovimento`} key={"viteinmovimento"}>
                            <button className="int__contenuti-pages-button">
                                <img src={require("../../assets/left2.png")} alt="Left" className="int__contenuti-icons"></img>
                            </button>
                            <div className='int__contenuti-navbar-title'>{t("Vite in movimento")} </div>
                        </Link>
                        :
                        <Link to={`/${lang}/livesonthemove`} key={"viteinmovimento"}>
                            <button className="int__contenuti-pages-button">
                                <img src={require("../../assets/left2.png")} alt="Left" className="int__contenuti-icons"></img>
                            </button>
                            <div className='int__contenuti-navbar-title'>{t("Vite in movimento")} </div>
                        </Link>
                    }
                </div>
            </div>
            <div className="int__contenuti-navbar-right-container">
                <div className="int__contenuti-navbar-right-container-box">
                    {lang==="it" ? 
                        <Link to={`/${lang}/reti_familiari`} key={"retifamiliari"}>
                            <button className="int__contenuti-pages-button">
                                <img src={require("../../assets/right2.png")} alt="Right" className="int__contenuti-icons"></img>
                            </button>
                            <div className='int__contenuti-navbar-title'>{t("Reti familiari")}</div>
                        </Link>
                        :
                        <Link to={`/${lang}/family_networks`} key={"retifamiliari"}>
                            <button className="int__contenuti-pages-button">
                                <img src={require("../../assets/right2.png")} alt="Right" className="int__contenuti-icons"></img>
                            </button>
                            <div className='int__contenuti-navbar-title'>{t("Reti familiari")}</div>
                        </Link>
                    }
                </div>
            </div>
        </div>
    </div>
    <div className="int__contenuti-testo-container-vert">
        <div className='int__contenuti-testo-container-horiz'>
            <div className="int__contenuti-title-name">
                {testo.titolo}
            </div>
            <div className="int__contenuti-testo" dangerouslySetInnerHTML={{__html: testo.testo}}>
            </div>
        </div>
    </div>
    <div className='int__elenco-global-container-vert'>
        <div className='int__elenco-global-container-horiz'>
            <div className='int__elenco-container'>
                <div className='int__elenco-form-container'> 
                    <p>{t("Cerca nomi di IF")}</p>
                    <div className='int__elenco-form'>
                        <input value={searchString}  onChange={(e) => setSearchString(e.target.value)} type="text" />
                    </div>
                    
                </div>
            </div>
            
            {searchString ==="" 
            ?   
                <>
                <LettereAlfabeto {...{elencoIntellettuali:elencoIntellettuali, refs:lettersRefs}}></LettereAlfabeto>
                <div className='int__elenco-elenco'> 
                {elencoIntellettuali.map((intellettuale, i) => {
                    const schedaIntellettuale = schedePresenti.find(scheda => scheda.idIntellettuale === intellettuale.id);
                    let primaLettera = intellettuale.cognome.charAt(0);                    
                    if (primaLettera !== letteraIniziale) { //Se la prima lettera del cognome (o dell'alias) è diversa dall'attuale lettera iniziale
                        letteraIniziale = primaLettera; //si cambia la lettera iniziale con la prima del cognome e si visualizza la lettera con la linea orizzontale
                        if (schedaIntellettuale) { //Se l'intellettuale attuale ha una scheda va visualizzato il link
                            if (intellettuale.alias) { //Se è un alias si ritorna la lettera iniziale e la linea e l'alias
                                return (
                                    <div key={"item_alias"+intellettuale.id}>
                                        <div className='int__elenco-elenco-iniziale' key={"alias"+primaLettera+i}>
                                            <div className='int__elenco-elenco-lettera' key={primaLettera+i+"alias"} ref={el => lettersRefs.current[i] = el}>
                                                <p>{letteraIniziale}</p>
                                            </div>
                                            <div className='int__elenco-hr' key={primaLettera+i+"alias_hr"}>
                                            </div>
                                        </div>
                                        <div className='int__elenco-item-elenco-alias' key={intellettuale.alias+ intellettuale.id+i}>
                                            <div className='int__elenco-item-elenco-alias-no-link capitalize' key={intellettuale.cognome+"alias"}>
                                                {intellettuale.cognome} 
                                            </div>
                                            <div className='int__elenco-item-elenco-alias-no-link ml-1'>
                                                {t("vedi")}
                                            </div>
                                            <Link className='int__elenco-item-elenco-alias-link'
                                                to={`/${lang}/${intellettuale.cognomeReale}/${intellettuale.nome}/${intellettuale.id}`}
                                                key={"alias"+intellettuale.id+i}>
                                                &nbsp;{intellettuale.cognomeReale}, {intellettuale.nome} {intellettuale.dateEsistenza}
                                            </Link>
                                        </div>
                                    </div>
                                )
                            }
                            if (schedaIntellettuale.modificato) { //Se la scheda dell'intellettuale è modificata va visualizzato il pallino rosso
                                return(
                                    <div key={"item_modificato"+intellettuale.id}>
                                        <div className='int__elenco-elenco-iniziale' key={primaLettera+i+"modificato"}>
                                            <div className='int__elenco-elenco-lettera' key={primaLettera+i+"modificato_elenco"} ref={el => lettersRefs.current[i] = el}>
                                                <p>{letteraIniziale}</p>
                                            </div>
                                            <div className='int__elenco-hr' key={primaLettera+i+"modificato_hr"}>
                                            </div>
                                        </div>
                                        <div className='int__elenco-elenco-item-container' key={primaLettera+i+"item"+"modificato"}>
                                            <Link className='int__elenco-item-elenco-link'
                                                to={`/${lang}/${intellettuale.cognome}/${intellettuale.nome}/${intellettuale.id}`}
                                                key={"modificato"+intellettuale+i.id}>
                                                {intellettuale.cognome}, {intellettuale.nome} {intellettuale.dateEsistenza}
                                            </Link>
                                            <div className='int__elenco-item-evidenza' key={primaLettera+i+"badge"}>
                                                <img src={NewBadge}/>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            else { //Se la scheda dell'intellettuale non è modificata non va visualizzato il pallino rosso ma solo il link alla scheda
                                return (
                                    <div key={"item_no_modificato"+intellettuale.id}>
                                        <div className='int__elenco-elenco-iniziale' key={primaLettera+i+"no_modificato"}>
                                            <div className='int__elenco-elenco-lettera' key={primaLettera+i+"lettera"+"no_modificato"} ref={el => lettersRefs.current[i] = el}>
                                                <p>{letteraIniziale}</p>
                                            </div>
                                            <div className='int__elenco-hr' key={primaLettera+i+"hr"+"no_modificato"}>
                                            </div>
                                        </div>
                                        <Link className='int__elenco-item-elenco-link'
                                            to={`/${lang}/${intellettuale.cognome}/${intellettuale.nome}/${intellettuale.id}`}
                                            key={"no_modificato"+intellettuale.id+i}>
                                            {intellettuale.cognome}, {intellettuale.nome} {intellettuale.dateEsistenza}
                                        </Link>
                                    </div>
                                )};
                        } //Se l'intellettuale attuale non ha una scheda attiva si visualizza il nome in grigio senza link
                        else {
                            if (intellettuale.alias) { //Se è un alias ma non c'è una scheda si ritorna null
                                return null
                            }
                            return (
                                <div key={"item_no_scheda"+intellettuale.id}>
                                    <div className='int__elenco-elenco-iniziale' key={primaLettera+"no_scheda"}>
                                        <div className='int__elenco-elenco-lettera' key={primaLettera+"lettera"+"no_scheda"} ref={el => lettersRefs.current[i] = el}>
                                            <p>{letteraIniziale}</p>
                                        </div>
                                        <div className='int__elenco-hr' key={primaLettera+"hr"+"no_scheda"}>
                                        </div>
                                    </div>
                                    <div className='int__elenco-item-elenco-no-link' key={intellettuale.id+"no_scheda"}>
                                        {intellettuale.cognome}, {intellettuale.nome} {intellettuale.dateEsistenza}
                                    </div>
                                </div>
                            )
                        }
                    } //Se la prima lettera del cognome invece è la stessa della lettera iniziale attuale non si deve visualizzare la lettera e la barra orizzontale
                    else {
                        if (schedaIntellettuale) {
                            if (intellettuale.alias) { //Se è un alias si ritorna l'alias
                                return (
                                    <div className='int__elenco-item-elenco-alias' key={intellettuale.alias+ intellettuale.id+"primo_alias"}>
                                        <div className='int__elenco-item-elenco-alias-no-link' key={intellettuale.cognome+"primo_alias"}>
                                            {intellettuale.cognome}
                                        </div>
                                        <div className='int__elenco-item-elenco-alias-no-link ml-1'>
                                            {t("vedi")}
                                        </div>
                                        <Link className='int__elenco-item-elenco-alias-link'
                                            to={`/${lang}/${intellettuale.cognomeReale}/${intellettuale.nome}/${intellettuale.id}`}
                                            key={"alias"+intellettuale.id+"primo_alias"}>
                                            &nbsp;{intellettuale.cognomeReale}, {intellettuale.nome} {intellettuale.dateEsistenza}
                                        </Link>
                                    </div>
                                )
                            } else {
                                if (schedaIntellettuale.modificato) {
                                    return(
                                        <div className='int__elenco-elenco-item-container' key={"primo_modificato_elenco_container"+i}>
                                            <Link className='int__elenco-item-elenco-link'
                                                to={`/${lang}/${intellettuale.cognome}/${intellettuale.nome}/${intellettuale.id}`}
                                                key={"primo_modificato"+intellettuale.id}>
                                                {intellettuale.cognome}, {intellettuale.nome} {intellettuale.dateEsistenza}
                                            </Link>
                                            <div className='int__elenco-item-evidenza' key={"primo_modificato"+i+"badge"}>
                                                <img src={NewBadge}/>
                                            </div>
                                        </div>
                                    )}
                                else {
                                    return (
                                        <Link className='int__elenco-item-elenco-link'
                                            to={`/${lang}/${intellettuale.cognome}/${intellettuale.nome}/${intellettuale.id}`}
                                            key={"primo_no_modificato"+intellettuale.id}>
                                            {intellettuale.cognome}, {intellettuale.nome} {intellettuale.dateEsistenza}
                                        </Link>
                                    )
                                }
                            }
                        }
                        else { //Se non c'è una scheda
                            if (intellettuale.alias) { //Se è un alias ma non c'è una scheda si ritorna null
                                return null
                            }
                            return (
                                <div className='int__elenco-item-elenco-no-link' key={"no-scheda"+intellettuale.id}>
                                    {intellettuale.cognome}, {intellettuale.nome} {intellettuale.dateEsistenza}
                                </div>
                        )}
                    }
                }
            )}            
            <Outlet/>
            </div>
            </>
            :   <div className='int__elenco-elenco' key={"elenco_results"}>
                    <div>
                        {searchResult.map((result,i) => 
                            <Link className='int__elenco-item-elenco-link'
                            to={`/${lang}/${result.intellettualiRelation.cognome}/${result.intellettualiRelation.nome}/${result.idIntellettuale}`}
                            target="_blank"
                            state={{searchString:searchString}}
                            key={result.id+result.intellettualiRelation.cognome+"i"}>
                            {result.intellettualiRelation.cognome}, {result.intellettualiRelation.nome} {result.intellettualiRelation.dateEsistenza}
                            </Link>
                        )}
                        <Outlet/>
                    </div>
                </div>   
            }
        </div>
    </div>
    </>
  )
}

export default Elenco
